/* eslint-disable no-undef */
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Vue from 'vue';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
export default new Vuex.Store({
  state: {
    userInfo: null,
    users: null,
  },

  getters: {
    user: state => {
      return state.userInfo;
    },
    nav: state => {
      return state.nav;
    },
    users: state => {
      return state.users;
    },
  },

  mutations: {
    SET_USER(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_NAV(state, nav) {
      state.nav = nav;
    },
    LOGOUT(state) {
      state.userInfo = null;
      state.nav = null;
    },

    SET_USERS(state, users) {
      state.users = users;
    },
  },
  actions: {
    async updateUser(context, action) {
      try {
        const updated = await service.updateUser(
          action.user,
          action.access_token
        );
        if (!updated) {
          throw 'Failed to update the user!';
        }
        return updated;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async getUserByEmail(context, action) {
      try {
        const user = await service.getUserByEmail(
          action.email,
          action.access_token
        );
        return user;
      } catch (error) {
        return null;
      }
    },

    async getUsers(context, action) {
      const users = await service.getAllUsers(action.access_token);
      context.commit('SET_USERS', users.data);
    },

    async updateUserRoles(context, action) {
      const user = await service.updateUserRoles(
        action.email,
        action.roles,
        action.access_token
      );
      return user;
    },
  },
  plugins: [vuexLocal.plugin],
  modules: {},
});
