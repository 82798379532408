<template>
  <div class="filter-bar ui basic segment grid">
    <div class="ui form">
      <div class="inline field">
        <input
          type="text"
          v-model="filterText"
          class="three wide column2"
          @keyup.enter="doFilter"
          :placeholder="mensajeFiltro"
          style="margin-left:-12px;"
        />
        <button class="button buttonblue" @click="doFilter">Filtrar</button>
        <button class="button buttondarkgrey" @click="resetFilter">Restablecer</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mensajeFiltro: String,
  },
  data() {
    return {
      filterText: '',
    };
  },
  methods: {
    doFilter() {
      this.$events.fire('filter-set', this.filterText);
    },
    resetFilter() {
      this.filterText = '';
      this.$events.fire('filter-reset', 'reseteo');
    },
  },
};
</script>
