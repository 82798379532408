var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx_mt" }, [
    _vm._m(0),
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _c("div", {}, [
      _c("div", { staticClass: "row" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.picked,
              expression: "picked",
            },
          ],
          staticClass: "input",
          attrs: { id: "toggle", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.picked)
              ? _vm._i(_vm.picked, null) > -1
              : _vm.picked,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.picked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.picked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.picked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.picked = $$c
              }
            },
          },
        }),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputData,
              expression: "inputData",
            },
          ],
          staticClass: "column grooveborder",
          attrs: { id: "inputTextArea" },
          domProps: { value: _vm.inputData },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputData = $event.target.value
            },
          },
        }),
        _c("p", {
          staticClass: "column grooveborder outputArea",
          staticStyle: { "white-space": "nowrap" },
          attrs: { disabled: "", id: "outputTextArea" },
        }),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "columnsmall textoCentrado" }, [
          _c(
            "button",
            { staticClass: "button buttonblue", on: { click: _vm.convertir } },
            [_vm._v(" Procesar MX ")]
          ),
        ]),
        _c("div", { staticClass: "columnsmall textoCentrado" }, [
          _c(
            "button",
            { staticClass: "button buttonblue", on: { click: _vm.download } },
            [_c("i", { staticClass: "fa fa-download" }), _vm._v(" Descargar ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "label",
        staticStyle: { width: "50%", "margin-left": "5px" },
        attrs: { for: "toggle" },
      },
      [
        _c("div", { staticClass: "left" }, [_vm._v(" Input ")]),
        _c("div", { staticClass: "switch" }, [
          _c("span", { staticClass: "slider round" }),
        ]),
        _c("div", { staticClass: "right" }, [_vm._v(" Output ")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "form-check",
        staticStyle: { position: "absolute", right: "3%" },
      },
      [
        _c(
          "label",
          {
            staticClass: "form-check-label",
            staticStyle: { "margin-right": "18px" },
          },
          [_vm._v("Guardar conversión ")]
        ),
        _c("input", {
          staticClass: "form-check-input",
          attrs: { type: "checkbox", checked: "", disabled: "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }