var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "recientes" } },
    [
      _vm._m(0),
      _vm._m(1),
      _c("br"),
      _c("FilterBar", { attrs: { mensajeFiltro: _vm.mensajeFiltro } }),
      _c(
        "div",
        {
          staticClass: "notification is-info textoCentrado",
          staticStyle: { "border-style": "!important none" },
        },
        [
          _c("pre", { staticStyle: { color: "#2ea7d2" } }, [
            _vm._v(_vm._s(_vm.actualizado) + " " + _vm._s(_vm.resultados)),
          ]),
        ]
      ),
      _c("div", { staticStyle: { overflow: "auto" } }, [
        _c(
          "table",
          {
            staticStyle: { width: "99% !important" },
            attrs: { id: "tableformat1" },
          },
          [
            _vm._m(2),
            _vm._l(_vm.registros, function (row) {
              return [
                _c("tr", { key: row.id }, [
                  _c("td", [
                    _vm._v(
                      _vm._s(row.formato_origen) +
                        " - " +
                        _vm._s(
                          row.tipo_operacion === "traduccion"
                            ? "traducción"
                            : "extracción de datos"
                        )
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(row.tipo_archivo_origen))]),
                  _c("td", [_vm._v(_vm._s(row.header_from))]),
                  _c("td", [_vm._v(_vm._s(row.header_to))]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(_vm._s(row.fecha_traduccion)),
                  ]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(
                      _vm._s(
                        row.tipo_operacion !== "traduccion"
                          ? "información principal"
                          : row.formato_destino +
                              " " +
                              row.tipo_archivo_traduccion +
                              (typeof row.sub_tipo_archivo_traduccion !==
                              "undefined"
                                ? " " + row.sub_tipo_archivo_traduccion
                                : "")
                      )
                    ),
                  ]),
                  _c("td", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("    "),
                    row.tipo_operacion.trim() === "traduccion"
                      ? _c("i", {
                          staticClass: "bi bi-display",
                          attrs: {
                            "data-toggle": "modal",
                            "data-target": "#myModal",
                            "data-backdrop": "static",
                            "data-focus": "true",
                            title: "Ver conversión",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.mostrarConversion(
                                row.contenido_origen,
                                row.contenido_destino,
                                row.formato_origen
                              )
                            },
                          },
                        })
                      : _c("i", {
                          staticClass: "bi bi-display",
                          staticStyle: { color: "#28a745" },
                          attrs: {
                            "data-toggle": "modal",
                            "data-target": "#myModal",
                            "data-backdrop": "static",
                            "data-focus": "true",
                            title: "Ver datos principales",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.mostrarDatosPrincipales(
                                row.contenido_origen,
                                row.maindata,
                                row.formato_origen
                              )
                            },
                          },
                        }),
                    _vm._v("    "),
                    row.tipo_operacion.trim() === "traduccion"
                      ? _c("i", {
                          staticClass: "bi bi-save2",
                          staticStyle: { color: "RoyalBlue" },
                          attrs: {
                            id: "b_guardarReporte_MT",
                            title: "Descargar MT como PDF",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.descargarMT(
                                row.direccion,
                                row.contenido_origen,
                                row.contenido_destino,
                                row.formato_origen,
                                row.fecha_traduccion
                              )
                            },
                          },
                        })
                      : _c("i", {
                          staticClass: "bi bi-save2",
                          staticStyle: { color: "#28a745" },
                          attrs: {
                            id: "b_guardarDatosPrincipales",
                            title: "Descargar datos principales como PDF",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.descargarMD(
                                row.direccion,
                                row.contenido_origen,
                                row.maindata,
                                row.formato_origen,
                                row.fecha_traduccion
                              )
                            },
                          },
                        }),
                    _vm._v("    "),
                    row.tipo_operacion.trim() === "traduccion"
                      ? _c("i", {
                          staticClass: "bi bi-save2-fill",
                          staticStyle: { color: "RoyalBlue" },
                          attrs: {
                            id: "b_guardarReporte_MT_TXT",
                            title: "Descargar MT como txt",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.descargarMT_TXT(
                                row.direccion,
                                row.contenido_origen,
                                row.contenido_destino,
                                row.formato_origen,
                                row.fecha_traduccion
                              )
                            },
                          },
                        })
                      : _c("i", {
                          staticClass: "bi bi-save2-fill",
                          staticStyle: { color: "#28a745" },
                          attrs: {
                            id: "b_guardarDatosPrincipales_TXT",
                            title: "Descargar datos principales como txt",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.descargarMD_TXT(
                                row.direccion,
                                row.contenido_origen,
                                row.maindata,
                                row.formato_origen,
                                row.fecha_traduccion
                              )
                            },
                          },
                        }),
                    _vm._v("    "),
                    _c("i", {
                      staticClass: "bi bi-save2",
                      staticStyle: { color: "#990000" },
                      attrs: {
                        id: "b_guardarReporte_MX",
                        title: "Descargar MX como PDF",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.descargarMX(
                            row.direccion,
                            row.contenido_origen,
                            row.contenido_destino,
                            row.formato_origen,
                            row.fecha_traduccion
                          )
                        },
                      },
                    }),
                    _vm._v("    "),
                    _c("i", {
                      staticClass: "bi bi-save2-fill",
                      staticStyle: { color: "#990000" },
                      attrs: {
                        id: "b_guardarReporte_MX_TXT",
                        title: "Descargar MX como txt",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.descargarMX_TXT(
                            row.direccion,
                            row.contenido_origen,
                            row.contenido_destino,
                            row.formato_origen,
                            row.fecha_traduccion
                          )
                        },
                      },
                    }),
                    _vm._v("    "),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("div", { staticClass: "modal fade", attrs: { id: "myModal" } }, [
        _c(
          "div",
          { staticClass: "modal-dialog modal-dialog-centered modal-xl" },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h4",
                  {
                    staticClass: "modal-title",
                    staticStyle: { "text-align": "center !important" },
                  },
                  [_vm._v(" Conversión de mensaje ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.enableIcons },
                  },
                  [_vm._v(" × ")]
                ),
              ]),
              _vm._m(3),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.enableIcons },
                  },
                  [_vm._v(" Cerrar ")]
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("Convertidos recientemente")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "sortable", attrs: { id: "_formato_origen" } }, [
        _vm._v(" Tipo de operación"),
        _c("i", {
          staticClass: "sort-icon grey sort icon",
          staticStyle: { opacity: "1", position: "relative", float: "right" },
        }),
      ]),
      _c(
        "th",
        { staticClass: "sortable", attrs: { id: "_tipo_archivo_origen" } },
        [
          _vm._v(" Formato"),
          _c("i", {
            staticClass: "sort-icon grey sort icon",
            staticStyle: { opacity: "1", position: "relative", float: "right" },
          }),
        ]
      ),
      _c("th", { staticClass: "sortable", attrs: { id: "_header_from" } }, [
        _vm._v(" Desde"),
        _c("i", {
          staticClass: "sort-icon grey sort icon",
          staticStyle: { opacity: "1", position: "relative", float: "right" },
        }),
      ]),
      _c("th", { staticClass: "sortable", attrs: { id: "_header_to" } }, [
        _vm._v(" Para"),
        _c("i", {
          staticClass: "sort-icon grey sort icon",
          staticStyle: { opacity: "1", position: "relative", float: "right" },
        }),
      ]),
      _c(
        "th",
        { staticClass: "sortable", attrs: { id: "_fecha_traduccion" } },
        [
          _vm._v(" Fecha operación"),
          _c("i", {
            staticClass: "sort-icon grey sort icon",
            staticStyle: { opacity: "1", position: "relative", float: "right" },
          }),
        ]
      ),
      _c("th", { staticClass: "sortable", attrs: { id: "_fprmatodestino" } }, [
        _vm._v(" Formato destino"),
        _c("i", {
          staticStyle: { opacity: "1", position: "relative", float: "right" },
        }),
      ]),
      _c("th", [_vm._v("Opciones")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "row", attrs: { id: "modal-row" } }, [
        _c("p", {
          staticClass: "column grooveborder outputArea",
          attrs: { disabled: "", id: "inputTextArea" },
        }),
        _c("p", {
          staticClass: "column grooveborder outputArea",
          staticStyle: { "white-space": "nowrap" },
          attrs: { disabled: "", id: "outputTextArea" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }