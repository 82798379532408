var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "floatingarea", attrs: { id: "floatingarea" } }),
    _c(
      "div",
      { staticClass: "sidenav", attrs: { id: "mySidenav" } },
      [
        _c(
          "a",
          {
            staticClass: "closebtn",
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.closeNav },
          },
          [_vm._v("×")]
        ),
        _c("router-link", { attrs: { to: "/home" } }, [_vm._v("Home")]),
        _vm._l(_vm.nav, function (contenedor) {
          return _c(
            "div",
            { key: contenedor.id_espacio },
            [
              contenedor.navegacion.component === ""
                ? _c("div", [
                    _c(
                      "b",
                      {
                        staticStyle: {
                          "margin-left": "15px",
                          color: "rgb(38, 175, 230)!important",
                          "text-align": "center!important",
                        },
                      },
                      [_vm._v(_vm._s(contenedor.navegacion.titulo))]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "b",
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: {
                              "margin-left": "15px",
                              color: "rgb(38, 175, 230)!important",
                              "padding-left": "0px!important",
                              "text-align": "center!important",
                            },
                            attrs: { to: "/" + contenedor.navegacion.path },
                          },
                          [_vm._v(_vm._s(contenedor.navegacion.titulo))]
                        ),
                      ],
                      1
                    ),
                  ]),
              _vm._l(contenedor.navegacion.members, function (seccion) {
                return _c("div", { key: seccion.elementoid }, [
                  seccion.component === ""
                    ? _c("div", [
                        _c(
                          "b",
                          {
                            class:
                              seccion.members.length > 0 ? "dropdown-btn" : "",
                          },
                          [
                            _vm._v(" " + _vm._s(seccion.titulo) + " "),
                            _c("i", {
                              class:
                                seccion.members.length > 0
                                  ? "fa fa-caret-down"
                                  : "",
                              attrs: { "v-if": seccion.members.length > 0 },
                            }),
                          ]
                        ),
                        seccion.members.length > 0
                          ? _c(
                              "div",
                              { staticClass: "dropdown-container" },
                              _vm._l(seccion.members, function (pagina) {
                                return _c(
                                  "router-link",
                                  {
                                    key: pagina.pageid,
                                    attrs: { to: pagina.path },
                                  },
                                  [_vm._v(" " + _vm._s(pagina.titulo) + " ")]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c(
                          "b",
                          {
                            class:
                              seccion.members.length > 0 ? "dropdown-btn" : "",
                          },
                          [
                            _c("router-link", { attrs: { to: seccion.path } }, [
                              _vm._v(" " + _vm._s(seccion.titulo) + " "),
                            ]),
                            _c("i", {
                              class:
                                seccion.members.length > 0
                                  ? "fa fa-caret-down"
                                  : "",
                              attrs: { "v-if": seccion.members.length > 0 },
                            }),
                          ],
                          1
                        ),
                        seccion.members.length > 0
                          ? _c(
                              "div",
                              { staticClass: "dropdown-container" },
                              _vm._l(seccion.members, function (pagina) {
                                return _c(
                                  "router-link",
                                  {
                                    key: pagina.pageid,
                                    attrs: { to: pagina.path },
                                  },
                                  [_vm._v(" " + _vm._s(pagina.titulo) + " ")]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ]),
                ])
              }),
            ],
            2
          )
        }),
      ],
      2
    ),
    _c("div", { staticClass: "navrow backgroundAppName" }, [
      _c("div", { staticClass: "backgroundAppName", attrs: { id: "main" } }, [
        _c("span", { staticStyle: { "font-size": "30px" } }, [
          _c("i", {
            staticClass: "fas fa-bars",
            staticStyle: {
              "margin-top": "8px",
              color: "rgb(255, 255, 255, 0.6)",
            },
            on: { click: _vm.openNav },
          }),
          _c("span", { staticClass: "translatortext" }, [
            _vm._v("  ISO 20022 Translator"),
          ]),
          _vm._m(0),
        ]),
        _c(
          "div",
          {
            staticClass: "backgroundColorApp",
            staticStyle: { float: "right", "margin-right": "8px" },
          },
          [
            !_vm.user
              ? _c("ul", {}, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        { staticClass: "nav-link", attrs: { to: "/login" } },
                        [
                          _c("span", { staticClass: "fas fa-lock" }),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "4px" } },
                            [_vm._v("Sign in / Sign up")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.user
              ? _c("div", { staticClass: "logout" }, [
                  _c(
                    "a",
                    { staticClass: "colorElemento1", attrs: { href: "/" } },
                    [_vm._v(" " + _vm._s(_vm.user.nickname) + " ")]
                  ),
                  _c("a", { on: { click: _vm.logout } }, [
                    _c("span", {
                      staticClass: "fas fa-sign-out-alt",
                      staticStyle: { "padding-left": "3px" },
                    }),
                    _c("span", { staticStyle: { "padding-left": "3px" } }),
                    _vm._v(" Logout"),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "translatortext",
        staticStyle: { "font-style": "italic" },
      },
      [_c("small", [_vm._v("  by Plan-IT")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }