var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _vm._m(0),
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fullrow" }, [
      _c("div", { staticClass: "textoCentrado" }, [
        _c("div", { staticClass: "textoIzquierda margen20 textoSize16" }, [
          _c("p", [_c("br")]),
          _c("h2"),
          _c("p", [
            _c("strong", [
              _vm._v(
                "  ISO 20022 Translator le permite seguir operando con su sistema actual de procesamiento de mensajes MT, aún cuando los mensajes que reciba sean del nuevo estándar MX (ISO 20022)."
              ),
            ]),
          ]),
          _c("p", [
            _vm._v("  Para este fin, cubre tres ejes fundamentales: "),
            _c("br"),
            _vm._v(" - la traducción y visualización de "),
            _c("strong", [_vm._v("pagos transfronterizos")]),
            _vm._v(". "),
            _c("br"),
            _vm._v(" - la traducción y visualización de "),
            _c("strong", [_vm._v("extractos bancarios")]),
            _vm._v(". "),
            _c("br"),
            _vm._v(" - la interpretación y vinculación de mensajes de "),
            _c("strong", [_vm._v("estado")]),
            _vm._v(" y "),
            _c("strong", [_vm._v("retorno")]),
            _vm._v(" a los pagos transfronterizos que corresponden. "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
          ]),
        ]),
        _c("div", { staticClass: "textoIzquierda margen20" }, [
          _c("h3", [_vm._v("¿Cómo lo realiza?")]),
          _c("br"),
        ]),
        _c("div", { staticClass: "textoIzquierda margen20 textoSize16" }, [
          _vm._v("   La solución se compone de 4 elementos fundamentales: "),
          _c("br"),
          _vm._v(" - "),
          _c("strong", [_vm._v("motor de traducción")]),
          _c("br"),
          _vm._v(" - "),
          _c("strong", [_vm._v("file workers")]),
          _vm._v(
            ": aplicaciones que leen determinados directorios en búsqueda de nuevos mensajes para traducir "
          ),
          _c("br"),
          _vm._v(" - "),
          _c("strong", [_vm._v("middleware")]),
          _vm._v(
            ": direcciona las consultas REST, proporciona autenticación y autorización de usuarios y gestiona el backoffice del CMS mostrado por el portal. "
          ),
          _c("br"),
          _vm._v(" - "),
          _c("strong", [_vm._v("portal web")]),
          _vm._v(
            ": permite solicitar traducciones y visualizar sus resultados, la visualización de los mensajes convertidos automáticamente a través de la solicitud de otras aplicaciones y de la trazabilidad de pagos transfronterizos. "
          ),
          _c("br"),
          _c("br"),
          _vm._v("  El "),
          _c("strong", [_vm._v("motor de traducción")]),
          _vm._v(" recibe consultas a través de su API REST desde: "),
          _c("div", { staticClass: "margen20" }, [
            _vm._v(" - procesos automatizados de disco (file workers) "),
            _c("br"),
            _vm._v(" - el usuario final a través del portal "),
            _c("br"),
            _vm._v(" - otras aplicaciones del cliente. "),
          ]),
          _vm._v(
            "  Una vez realizada la traducción entre estándares, devuelve al solicitante el resultado, y de ser necesario lo escribe en disco (en una carpeta configurable por el cliente) para que otra aplicación, por ejemplo un Core de Comex, lea el archivo MT y pueda trabajar sobre él. "
          ),
          _c("br"),
        ]),
        _c("div", { staticClass: "textoIzquierda margen20 textoSize16" }, [
          _c("img", {
            staticClass: "centerImage",
            attrs: { src: "arquitectura_scs.png", alt: "" },
          }),
          _c("br"),
        ]),
        _c("div", { staticClass: "textoIzquierda margen20 textoSize16" }, [
          _vm._v(
            "  Independientemente del origen de la solicitud, todas las traducciones y los mensajes recibidos son guardados para su posterior consulta y visualización. "
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "  Los mensajes de estado y de retorno de pagos transfronterizos que no poseen traducción, o bien no son procesados por el Core de Comex MT (u otra aplicación del cliente encargada de procesarlos), pueden visualizarse vinculados a aquellos mensajes que los originaron en el portal de la solución, a fin de tener una "
          ),
          _c("strong", [_vm._v("trazabilidad")]),
          _vm._v(" de nuestras operaciones. "),
          _c("br"),
        ]),
        _c("div", { staticClass: "textoIzquierda margen20 textoSize16" }, [
          _c("br"),
          _vm._v(
            " Si desea conocer más acerca de nuestra solución, o bien agendar una demo, no dude en contactarnos a través de nuestro Linkedin "
          ),
          _c(
            "a",
            {
              staticStyle: { color: "#2EA7D2!important" },
              attrs: {
                href: "https://comex.plan-it.com.ar/",
                target: "_blank",
              },
            },
            [_c("strong", [_vm._v("Plan-IT Comercio Exterior Swift IT")])]
          ),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }