import {_postSecPlain,_postSecPlainSpace,_getSecSpace} from './rest';
const traducirMTaMXPath = '/translate/swift/MT/toMX';
const traducirMXaMTPath = '/translate/swift/MX/toMT';
const lastConvertedPath = '/translate/lastconverted';
export {_traducirMTaMX,_traducirMXaMT,_tomarRecientes};
let loggedIn;
try {
  loggedIn = JSON.parse(localStorage.getItem('vuex')).userInfo;
} catch {
  loggedIn = false;
}
const _traducirMTaMX = (datos,espacio,espacioid) => {
  return new Promise(resolve => {
    _traducirMTaMXCall(datos,espacio,espacioid, data => {
      resolve(data);
    });
  });
};
function _traducirMTaMXCall(datos,espacio,espacioid, callback) {
  loggedIn.space = espacio;
  loggedIn.spaceid = espacioid;
  _postSecPlainSpace(traducirMTaMXPath, datos, loggedIn, function(resultado) {
    callback(resultado);
  });
}

const _traducirMXaMT = (datos,espacio,espacioid) => {
  return new Promise(resolve => {
    _traducirMXaMTCall(datos,espacio,espacioid, data => {
      resolve(data);
    });
  });
};
function _traducirMXaMTCall(datos,espacio,espacioid, callback) {
  loggedIn.space = espacio;
  loggedIn.spaceid = espacioid;
  _postSecPlainSpace(traducirMXaMTPath, datos, loggedIn, function(resultado) {
    callback(resultado);
  });
}
const _tomarRecientes = (espacio,espacioid) => {
  
  return new Promise(resolve => {
    _tomarRecientesCall(espacio,espacioid, data => {
      resolve(data);
    });
  });
};
function _tomarRecientesCall(espacio,espacioid, callback) {
  loggedIn.space = espacio;
  loggedIn.spaceid = espacioid;
  _getSecSpace(lastConvertedPath, loggedIn, function(resultado) {
    callback(resultado);
  });
}