const crypto = require('crypto');
const algorithm = 'aes-256-cbc';
const key = 'fpK92jhnf928KapqGhlr5t96l4apmgOn';
const ivb = 'Hunq430PfvWnl68A';
const keyb = 'Pdewer1uk7dyt7dfbWFRW41ger72gpyi';
function _encrypt(text) {
  let iv = crypto.randomBytes(16);
  let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return {iv: iv.toString('hex'), encryptedData: encrypted.toString('hex')};
}

// eslint-disable-next-line no-unused-vars
function _decrypt(text) {
  let iv = Buffer.from(text.iv, 'hex');
  let encryptedText = Buffer.from(text.encryptedData, 'hex');
  let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
function _encryptPass(text) {
  let iv = ivb;
  let cipher = crypto.createCipheriv(algorithm, Buffer.from(keyb), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return {encryptedData: encrypted.toString('hex')};
}
export {_encrypt, _decrypt, _encryptPass};
