<template>
  <div id="app20022">
    <NavBar class="fixed" />
    <router-view />
  </div>
</template>
<script>
import NavBar from '@/components/nav-bar';
export default {
  components: {NavBar},
};
</script>
<style lang="scss">
@import '@/design/index.scss';
</style>
