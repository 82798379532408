import Vue from 'vue';
import App from './App';
//import App from '@/App'; * from
import router from './router';
import VueCookies from 'vue-cookies';
import 'core-js';
import MXaMT from './views/MX';
import Recientes from './views/Recientes';
import Home from './views/Home';
import store from './store/index';
import {mapGetters} from 'vuex';
import MTaMX from './views/MT';



Vue.config.productionTip = false;
Vue.use(VueCookies);
if(window.self === window.top) {
new Vue({
  computed: {
    ...mapGetters(['nav']),
  },
  data() {

    return {
      componentes: [MTaMX,MXaMT,Recientes,Home],
      ejecutado: 1,
      jsonArray: Array,
    };
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    getDynamicRoutes: function(nav, addRoutes) {
      if (this.nav !== null && this.nav !== undefined) {
        this.processData(nav, addRoutes);
      }
    },
    processData: function(routes, addRoutes) {
      let contadornivel1 = 0;
      let cantidadComponentes = this.componentes.length;
      let componenteActual = '';
      let newRoute;
      
      for (
        contadornivel1 = 0;
        contadornivel1 < routes.length;
        contadornivel1++
      ) {
        
        let spaceNm = routes[contadornivel1].navegacion.path;
        let spaceId = routes[contadornivel1].navegacion.contenedorid;
        let pathNivelUno = '/' + routes[contadornivel1].navegacion.path;
        newRoute = [
          {
            path: pathNivelUno,
            component: null,
          },
        ];
        componenteActual = '' + routes[contadornivel1].navegacion.component;
        if (componenteActual !== '') {
          for (let contador = 0; contador < cantidadComponentes; contador++) {
            let ruta = this.componentes[contador].__file;

            if (ruta.indexOf(routes[contadornivel1].component) > -1) {
              newRoute[0].component = this.componentes[contador];
            }
          }
        }
        this.$router.addRoute(newRoute[0]);
        let contadornivel2 = 0;
        let nivel2 = routes[contadornivel1].navegacion.members;
        for (
          contadornivel2 = 0;
          contadornivel2 < nivel2.length;
          contadornivel2++
        ) {
          let pathnivel2 = nivel2[contadornivel2].path;
          if (addRoutes) {
            let newRouteNivel2 = [
              {
                name: nivel2[contadornivel2].name,
                path: pathnivel2,
                component: null,
                meta: nivel2[contadornivel2].meta,
                props: { space: spaceNm,spaceid:spaceId },
              },
            ];
            componenteActual = '' + nivel2[contadornivel2].component;
            if (componenteActual !== '') {
              for (let contador = 0; contador < cantidadComponentes; contador++) {
                //en linux no funciona __file y hay que usar name
                //let ruta = this.componentes[contador].__file;
                let name = this.componentes[contador].name;
                try{
                  if (nivel2[contadornivel2].component.indexOf(name) > -1) {
                    
                    newRouteNivel2[0].component = this.componentes[contador];
                  }
                }catch{}
                
              }
            }

            this.$router.addRoute(newRouteNivel2[0]);
          } else {
            this.$router.removeRoute(nivel2[contadornivel2].name);
          }
          let contadornivel3 = 0;
          let nivel3 = nivel2[contadornivel2].members;
          for (
            contadornivel3 = 0;
            contadornivel3 < nivel3.length;
            contadornivel3++
          ) {
            let pathnivel3 = nivel3[contadornivel3].path;
            if (addRoutes) {
              let newRouteNivel3 = [
                {
                  name: nivel3[contadornivel3].name,
                  path: pathnivel3,
                  component: null,
                  meta: nivel3[contadornivel3].meta,
                  props: { space: spaceNm,spaceid:spaceId },
                },
              ];
              componenteActual = '' + nivel3[contadornivel3].component;
              if (componenteActual !== '') {
                for (let contador = 0; contador < cantidadComponentes; contador++) {
                  //en linux no funciona __file y hay que usar name
                  //let ruta = this.componentes[contador].__file;
                  let name = this.componentes[contador].name;
                  try{
                  if (nivel3[contadornivel3].component.indexOf(name) > -1) {
                    newRouteNivel3[0].component = this.componentes[contador];
                  }
                }
                  catch{}
                }
              }
              this.$router.addRoute(newRouteNivel3[0]);
            } else {
              this.$router.removeRoute(nivel3[contadornivel3].name);
            }
          }
        }
      }
    },
  },

  created() {
    this.getDynamicRoutes(this.nav, true);
    let cookieExistente = '' + this.$cookies.get('lastNavDate');
    if (cookieExistente === 'null') {
      this.$cookies.set(
        'lastNavDate',
        new Date().getTime(),
        '2021-09-15T01:58:16.533Z'
      );
    }   
  },

  router,
  store,
  render: h => h(App),
}).$mount('#app');
//VueHelmet.frameguard({ action: "SAMEORIGIN" });
//esto es para poder identificar paginas que no quiero que se carguen
//es una manera de inhabilitar link
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '', '/home'];
  const backgroundPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  let loggedIn;
  try {
    loggedIn = JSON.parse(localStorage.getItem('vuex')).userInfo;
  } catch {
    loggedIn = false;
  }
  

  const backgroundNone = !backgroundPages.includes(to.path);
  if (backgroundNone) {
    document.body.style.backgroundImage = '';
  }
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});
}