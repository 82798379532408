<template>
  <div class="mt_mx">
    <p><br /></p>
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <div class="">
      <div>
        <div class="row">
          <div class="form-check" style="position: absolute; right: 3%">
            <label class="form-check-label" style="margin-right: 18px"
              >Guardar
            </label>
            <input type="checkbox" class="form-check-input" checked disabled />
          </div>
          <p><br /></p>
        </div>
        <br />
      </div>
      <div class="row">
        <textarea
          class="column grooveborder"
          id="inputTextArea"
          v-model="inputData"
        ></textarea>
        <p
          disabled
          class="column grooveborder outputArea"
          id="outputTextArea"
        ></p>
      </div>
      <div class="row">
        <div class="columnsmall textoCentrado">
          <button class="button buttonblue" @click="convertir">
            Procesar MT
          </button>
        </div>
        <div class="columnsmall textoCentrado">
          <button class="button buttonblue centeredElement" @click="download">
            <i class="fa fa-download"></i> Descargar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {_traducirMTaMX} from "./scripts/translate";
export default {
  name: "MT",
  props: {
    space: String,
    spaceid: String,
  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      titulo: "Procesar MT",
      inputData: "",
      outputData: "",
      convertido: "",
      componentKey: 0,
      fecha_traduccion: "",
      direccion: "",
      mensajeError:
        "El mensaje no pudo convertirse. <br>Por favor, verifica que tus datos sean correctos, que el formato que intentas traducir sea válido e inténtalo nuevamente.",
      mensajeProcesando: "Procesando mensaje",
    };
  },
  created() {
    document.title = process.env.VUE_APP_TITLE + process.env.VUE_APP_SUBTITLE;
  },
  mounted() {
    //console.log("space ->" +space);
  },
  methods: {
    convertir_: function () {
      //console.log("ingresado:" + this.inputData);
    },
    formatXml: function (xml, colorize, indent) {
      function esc(s) {
        return s.replace(/[-\/&<> ]/g, function (c) {
          // Escape special chars
          return c == " " ? "&nbsp;" : "&#" + c.charCodeAt(0) + ";";
        });
      }

      var se = '<p class="xel">',
        tb = '<div class="xtb">',
        d = 0,
        i,
        re = "",
        ib,
        sd = '<p class="xdt">',
        tc = '<div class="xtc">',
        ob,
        at,
        sz = "</p>",
        sa = '<p class="xat">',
        tz = "</div>",
        ind = esc(indent || "  ");

      if (!colorize) se = sd = sa = sz = "";

      xml
        .match(/(?<=<).*(?=>)|$/s)[0]
        .split(/>\s*</)
        .forEach(function (nd) {
          ob = nd.match(/^([!?\/]?)(.*?)([?\/]?)$/s); // Split outer brackets
          ib = ob[2].match(/^(.*?)>(.*)<\/(.*)$/s) || ["", ob[2], ""]; // Split inner brackets
          at = ib[1].match(/^--.*--$|=|('|").*?\1|[^\t\n\f \/>"'=]+/g) || [""]; // Split attributes
          if (ob[1] == "/") d--; // Decrease indent
          re +=
            tb +
            tc +
            ind.repeat(d) +
            tz +
            tc +
            esc("<" + ob[1]) +
            se +
            esc(at[0]) +
            sz;
          for (i = 1; i < at.length; i += 3)
            re +=
              esc(" ") + sa + esc(at[i]) + sz + "=" + sd + esc(at[i + 2]) + sz;
          re += ib[2]
            ? esc(">") + sd + esc(ib[2]) + sz + esc("</") + se + ib[3] + sz
            : "";
          re += esc(ob[3] + ">") + tz + tz;
          if (ob[1] + ob[3] + ib[2] == "") d++; // Increase indent
        });

      return re;
    },
    convertir: function () {
      let mtContent = this.inputData;
      let self = this;
      //console.log("ingresado:" + mtContent);
      if (
        mtContent !== null &&
        mtContent !== "undefined" &&
        mtContent.length > 0
      ) {
        document.getElementById(
                "outputTextArea",
              ).innerHTML = this.mensajeProcesando;
        _traducirMTaMX(mtContent, this.space, this.spaceid)
          .then((resp) => {
            console.log(resp);
            //let regresado = JSON.parse(JSON.stringify(resp.data.contenido_destino));
            let respuesta="";
            let error = false;
            try {
              resp = JSON.parse(resp);
            } catch {}
            let arr = resp.data.data;
            let completeArray = JSON.parse("[]");
           
            for (let i = 0; i < arr.length; i++){
              let currentJsonObj = JSON.parse("{}");
              let resp = arr[i];
              let numeroMensaje = i+1;
             
              try{
              let tipoOperacion = resp.tipo_operacion.trim().toLowerCase() ==='traduccion' ? 'traducción':'datos principales';
                respuesta += "<!------- Mensaje "+numeroMensaje+" :  "+tipoOperacion+"  ----->";
                respuesta +=" <!------- Origen "+resp.formato_origen.trim().toUpperCase()+" "+resp.tipo_archivo_origen.trim().toLowerCase()+" "+resp.sub_tipo_archivo_origen.trim().toLowerCase()+"  ----->";
                respuesta +=" <!------- Destino "+resp.formato_destino.trim().toUpperCase()+" "+resp.tipo_archivo_traduccion.trim().toLowerCase()+" "+resp.sub_tipo_archivo_traduccion.trim().toLowerCase()+"  ----->";
                respuesta +=" <!------- Resultado: "+resp.resultado.trim().toUpperCase()+"  ----->";
                let formatoMX = resp.tipo_archivo_traduccion.trim().toLowerCase().replace(/\./g,"");
                currentJsonObj.formato = formatoMX;
              }
              catch (err){
                console.log(err);
                resp.resultado = "error";
                if(arr.length == 1){
                  
                  document.getElementById(
                  "outputTextArea",
                ).innerHTML = this.mensajeError;
                }
                else{
                  
                  respuesta += "<!------- Mensaje "+numeroMensaje+"  ----->";
                  respuesta +=" <!------- Resultado: ERROR  ----->";
                  document.getElementById("outputTextArea").innerHTML = respuesta;
                }
                currentJsonObj.status = "error";
                
              }
              //respuesta +="&#60;/p&#61;";
              if (resp.resultado.trim().toLowerCase() !== "error") {
                try {
                  respuesta += resp.contenido_destino;
                  respuesta =
                    respuesta.charAt(0) === '"'
                      ? respuesta.substring(1, respuesta.length)
                      : respuesta;
                  respuesta =
                    respuesta.charAt(respuesta.length - 1) === '"'
                      ? respuesta.substring(0, respuesta.length - 1)
                      : respuesta;
                } catch {
                  error = true;
                  respuesta = "" + JSON.stringify(resp.contenido_destino);
                  respuesta =
                    respuesta.charAt(0) === '"'
                      ? respuesta.substring(1, respuesta.length)
                      : respuesta;
                  respuesta =
                    respuesta.charAt(respuesta.length - 1) === '"'
                      ? respuesta.substring(0, respuesta.length - 1)
                      : respuesta;
                }
                let respuesta_para_archivo =
                  "" + JSON.stringify(resp.contenido_destino);
                respuesta_para_archivo =
                  respuesta_para_archivo.charAt(0) === '"'
                    ? respuesta_para_archivo.substring(
                        1,
                        respuesta_para_archivo.length,
                      )
                    : respuesta_para_archivo;
                respuesta_para_archivo =
                  respuesta_para_archivo.charAt(
                    respuesta_para_archivo.length - 1,
                  ) === '"'
                    ? respuesta_para_archivo.substring(
                        0,
                        respuesta_para_archivo.length - 1,
                      )
                    : respuesta_para_archivo;
                //this.convertido = respuesta_para_archivo;
                currentJsonObj.content = respuesta_para_archivo;
                currentJsonObj.status = "ok";
                this.fecha_traduccion = resp.fecha_traduccion;
                this.direccion = resp.direccion;
                if (!error) {
                  respuesta = this.formatXml(respuesta, true);
                }

                
              } else {
                /*this.convertido = "";
                document.getElementById(
                  "outputTextArea",
                ).innerHTML = this.mensajeError;*/
                currentJsonObj.content = "";
                currentJsonObj.formato = "";
                currentJsonObj.status = "error";
              }
              document.getElementById("outputTextArea").innerHTML = respuesta;
              completeArray.push(currentJsonObj);
            }
            this.convertido = JSON.stringify(completeArray);
          })
          .catch((err) => {
            //console.log(err);
            let currentJsonObj = JSON.parse("[]");
            this.convertido = JSON.stringify(currentJsonObj);
              document.getElementById(
                "outputTextArea",
              ).innerHTML = this.mensajeError;

          });
      }
    },
    download: function () {
      if (this.convertido.length > 0) {
      
        let arr= JSON.parse(this.convertido);
        for (let i = 0; i < arr.length; i++){
             
              let resp = arr[i];
                if(resp.status.trim().toLowerCase() ==='ok' && resp.status.trim() !==''){
                  let mensajeArchivo = resp.content.replace(/\\n/g, "%0D%0A");
                  let date = this.fecha_traduccion;
                  date = date.trim();
                  date = date.replace(/-/g, "");
                  date = date.replace(/\./g, "");
                  date = date.replace(/\:/g, "");
                  date = date.replace(/\s/g, "");
                  let hiddenElement = document.createElement("a");
                  hiddenElement.href = "data:text/plain," + mensajeArchivo;
                  hiddenElement.target = "_blank";
                  hiddenElement.download = "mx_"+resp.formato+"_" + date + "_" + this.direccion + ".txt";
                  document.body.appendChild(hiddenElement);
                  hiddenElement.click();
                  document.body.removeChild(hiddenElement);
                }
                
            }
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
  },
  mounted() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.render = false;
      this.$nextTick(() => {
        this.inputData = "";
        this.outputData = "";
        document.getElementById(
                "outputTextArea",
              ).innerHTML = "";
        this.convertido = "";
        this.componentKey++;
        this.$forceUpdate();
      });
    },
  },
};
</script>
