<template>
  <div class="home">
    <!--<div class="centered-logo">
      <img  alt="logo" src="../assets/logo.png" />
      <p><br></p>
    </div>-->
  <p><br></p>
   <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
        <div class="fullrow">
  <div class="textoCentrado">
   <div class="textoIzquierda margen20 textoSize16">
    <p><br></p>
    <h2></h2>
    <p><strong> &nbsp;ISO 20022 Translator le permite seguir operando con su sistema
      actual de procesamiento de mensajes MT, aún cuando los mensajes que reciba sean del nuevo estándar MX (ISO 20022).</strong></p>
    <p> &nbsp;Para este fin, cubre tres ejes fundamentales:
    <br> - la traducción y visualización de <strong>pagos transfronterizos</strong>.
    <br> - la traducción y visualización de <strong>extractos bancarios</strong>.
    <br> - la interpretación y vinculación de mensajes de <strong>estado</strong> y <strong>retorno</strong> a los pagos transfronterizos que corresponden.
          <br>      <br>
    </p>
    </div>
    <div class="textoIzquierda margen20">

       <h3>¿Cómo lo realiza?</h3>
      <br>
      </div>
  
    <div class="textoIzquierda margen20 textoSize16">
     &nbsp; La solución se compone de 4 elementos fundamentales:
      <br> - <strong>motor de traducción</strong> 
      <br> - <strong>file workers</strong>: aplicaciones que leen determinados directorios en búsqueda de nuevos mensajes para traducir
      <br> - <strong>middleware</strong>: direcciona las consultas REST, proporciona autenticación y autorización de usuarios y 
      gestiona el backoffice del CMS mostrado por el portal.
      <br> - <strong>portal web</strong>: permite solicitar traducciones y visualizar sus resultados, la visualización 
      de los mensajes convertidos automáticamente a través de la solicitud de otras aplicaciones y de la trazabilidad de pagos transfronterizos.
    <br>
    <br>
    &nbsp;El <strong>motor de traducción</strong> recibe consultas a través de su API REST desde:
    <div class="margen20">
    - procesos automatizados de disco (file workers)
    <br> - el usuario final a través del portal
    <br> - otras aplicaciones del cliente.
    </div>
    &nbsp;Una vez realizada la traducción entre estándares, devuelve al solicitante el resultado, 
    y de ser necesario lo escribe en disco (en una carpeta configurable por el cliente) 
    para que otra aplicación, por ejemplo un Core de Comex, lea el archivo MT y pueda trabajar sobre él.
    <br>
     </div>
   

     <div class="textoIzquierda margen20 textoSize16">
     <img src="arquitectura_scs.png" alt="" class="centerImage"> 
           <br> 
  </div>
   <div class="textoIzquierda margen20 textoSize16">
   &nbsp;Independientemente del origen de la solicitud, todas las traducciones y 
    los mensajes recibidos son guardados para su posterior consulta y visualización.
    <br> 
    <br>
    &nbsp;Los mensajes de estado y de retorno de pagos transfronterizos que no poseen traducción, o bien no son procesados por 
    el Core de Comex MT (u otra aplicación del cliente encargada de procesarlos), pueden visualizarse vinculados a aquellos mensajes que los originaron en el portal de la solución, a fin de tener una
    <strong>trazabilidad</strong> de nuestras operaciones.
    <br> 

  </div>
  <div class="textoIzquierda margen20 textoSize16">
    <br>
    Si desea conocer más acerca de nuestra solución, o bien agendar una demo, no dude en contactarnos a través de nuestro Linkedin
     <a style="color:#2EA7D2!important;" href="https://comex.plan-it.com.ar/"
          target="_blank"><strong>Plan-IT Comercio Exterior Swift IT</strong></a>
           <br> <br>
  </div>
  </div> </div>

  </div>
</template>


<script>

import Vue from 'vue';
import VueEvents from 'vue-events';

Vue.use(VueEvents);


export default {
  name: 'app',
  components: {
    //FilterBar,
    //MapModal,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      titulo: 'Bienvenido a  ISO 20022 Translator',
   };
  },
  created() {},
  methods: {
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('exampleModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#exampleModal').modal({backdrop: false});
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style lang="scss">
@import '@/design/index.scss';
body {
  overflow-x: auto !important ;
}
.centerImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
