var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt_mx" }, [
    _vm._m(0),
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _c("div", {}, [
      _vm._m(1),
      _c("div", { staticClass: "row" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputData,
              expression: "inputData",
            },
          ],
          staticClass: "column grooveborder",
          attrs: { id: "inputTextArea" },
          domProps: { value: _vm.inputData },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.inputData = $event.target.value
            },
          },
        }),
        _c("p", {
          staticClass: "column grooveborder outputArea",
          attrs: { disabled: "", id: "outputTextArea" },
        }),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "columnsmall textoCentrado" }, [
          _c(
            "button",
            { staticClass: "button buttonblue", on: { click: _vm.convertir } },
            [_vm._v(" Procesar MT ")]
          ),
        ]),
        _c("div", { staticClass: "columnsmall textoCentrado" }, [
          _c(
            "button",
            {
              staticClass: "button buttonblue centeredElement",
              on: { click: _vm.download },
            },
            [_c("i", { staticClass: "fa fa-download" }), _vm._v(" Descargar ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "form-check",
            staticStyle: { position: "absolute", right: "3%" },
          },
          [
            _c(
              "label",
              {
                staticClass: "form-check-label",
                staticStyle: { "margin-right": "18px" },
              },
              [_vm._v("Guardar ")]
            ),
            _c("input", {
              staticClass: "form-check-input",
              attrs: { type: "checkbox", checked: "", disabled: "" },
            }),
          ]
        ),
        _c("p", [_c("br")]),
      ]),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }