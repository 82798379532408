<template>
  <div class="mx_mt">
       <p><br></p>
   <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <div class="">
      <div class="row">
      <input class="input" id="toggle" type="checkbox" v-model="picked">
        
        <label class="label" for="toggle" style="width:50%;margin-left:5px;">
        <div class="left">
          Input
        </div>

        <div class="switch">
          <span class="slider round"></span>
        </div>

        <div class="right">
        Output
        </div>
        
      </label>
      <div class="form-check" style="position: absolute; right: 3%;" >
        <label class="form-check-label" style="margin-right: 18px;">Guardar conversión  </label>
        <input  type="checkbox" class="form-check-input" checked disabled>
        
        </div>
        <p><br></p>
       </div>
       
      <div class="row">
        <textarea
          class="column grooveborder"
          id="inputTextArea"
          v-model="inputData"
        ></textarea>
         <p disabled
          class="column grooveborder outputArea"
          id="outputTextArea"
          style = "white-space: nowrap; "
        ></p> 
      </div>
      <div class="row">
        <div class="columnsmall textoCentrado">
          <button class="button buttonblue" @click="convertir">
            Procesar MX
          </button>
        </div>
        <div class="columnsmall textoCentrado">
          <button class="button buttonblue" @click="download">
            <i class="fa fa-download"></i> Descargar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {_traducirMXaMT} from './scripts/translate';
export default {
  name: "MX",
   props: {
    space: String,
    spaceid:String,
  },
  components: {
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      picked: false,
      titulo: 'Procesar MX',
      inputData: "",
      outputData:"",
      convertido: "",
      componentKey: 0,
      fecha_traduccion: "",
      direccion: "",
      mensajeError: "El archivo no pudo convertirse.<br>Por favor, verifica que tus datos sean correctos, que el formato que intentas traducir sea válido e inténtalo nuevamente.",
      mensajeProcesando: "Procesando mensaje",
   };
  },
  created() {
    document.title = process.env.VUE_APP_TITLE + process.env.VUE_APP_SUBTITLE;
    this.iphost = process.env.VUE_APP_BASE_URL;
  },
  methods: {
    convertir_: function () {
      //console.log("ingresado:" + this.inputData);
    },
    convertir: function () {
      let mtContent = this.inputData;
      let self = this;
      let inputOutput = this.picked ? "{OUTPUT}":"{INPUT}";
      mtContent = inputOutput + mtContent;
      if (
        mtContent !== null &&
        mtContent !== "undefined" &&
        mtContent.length > 0
      ) {
        document.getElementById(
                "outputTextArea",
              ).innerHTML = this.mensajeProcesando;
    _traducirMXaMT(mtContent,this.space,this.spaceid)
      .then(resp => {
          let respuestaTotal = "" ;
          let error = false;
          try{
            resp = JSON.parse(resp);
            
          }
          catch{}
          let arr = resp.data.data;
          
          let completeArray = JSON.parse("[]");
          for (let i = 0; i < arr.length; i++){
            let respuesta = "" ;
            let currentJsonObj = JSON.parse("{}");
            let resp = arr[i];
            let numeroMensaje = i+1;
            try{
               let tipoOperacion = resp.tipo_operacion.trim().toLowerCase() ==='traduccion' ? 'traducción':'datos principales';
                respuestaTotal  += "<span style='color:#358d65'>";
                respuestaTotal  += "&#60;!------- Mensaje "+numeroMensaje+" :  "+tipoOperacion+"  -----&#62;";
                respuestaTotal  += "<br>&#60;!------- Origen "+resp.formato_origen.trim().toUpperCase()+" "+resp.tipo_archivo_origen.trim().toLowerCase()+" "+resp.sub_tipo_archivo_origen.trim().toLowerCase()+"  -----&#62;";
                respuestaTotal  += "<br>&#60;!------- Destino "+resp.formato_destino.trim().toUpperCase()+" "+resp.tipo_archivo_traduccion.trim().toLowerCase()+" "+resp.sub_tipo_archivo_traduccion.trim().toLowerCase()+"  -----&#62;";
                respuestaTotal  += "<br>&#60;!------- Resultado: "+resp.resultado.trim().toUpperCase()+"  -----&#62;";
                respuestaTotal += "<br></span>"
                let formatoMT = resp.tipo_archivo_traduccion.trim().toLowerCase().replace(/\./g,"");
                currentJsonObj.formato = formatoMT;
              }
              catch (err){
                resp.resultado = "error";
                if(arr.length == 1){
                  
                  document.getElementById(
                  "outputTextArea",
                ).innerHTML = this.mensajeError;
                }
                else{
                  
                  respuesta += "<!------- Mensaje "+numeroMensaje+"  ----->";
                  respuesta +=" <!------- Resultado: ERROR  ----->";
                }
                currentJsonObj.status = "error";
                
              }
         
            if(resp.resultado !== "error"){
              let respuesta_para_archivo = "";
              if(resp.tipo_operacion.trim().toLowerCase() ==='traduccion'){
                try{
                respuesta += resp.contenido_destino;
                respuesta =
                  respuesta.charAt(0) === '"'
                    ? respuesta.substring(1, respuesta.length)
                    : respuesta;
                respuesta =
                  respuesta.charAt(respuesta.length - 1) === '"'
                    ? respuesta.substring(0, respuesta.length - 1)
                    : respuesta;

              }
              catch{
                error = true;
                respuesta += "" + JSON.stringify(resp.contenido_destino);
                respuesta =
                  respuesta.charAt(0) === '"'
                    ? respuesta.substring(1, respuesta.length)
                    : respuesta;
                respuesta =
                  respuesta.charAt(respuesta.length - 1) === '"'
                    ? respuesta.substring(0, respuesta.length - 1)
                    : respuesta;
              }
              respuesta_para_archivo = "" + JSON.stringify(resp.contenido_destino);
            }
            else{
              respuesta += resp.maindata;
              respuesta_para_archivo = resp.maindata;
            }
            
            respuesta_para_archivo =
              respuesta_para_archivo.charAt(0) === '"'
                ? respuesta_para_archivo.substring(1, respuesta_para_archivo.length)
                : respuesta_para_archivo;
            respuesta_para_archivo =
              respuesta_para_archivo.charAt(respuesta_para_archivo.length - 1) === '"'
                ? respuesta_para_archivo.substring(0, respuesta_para_archivo.length - 1)
                : respuesta_para_archivo;
            
            currentJsonObj.status = "ok";
            this.fecha_traduccion = resp.fecha_traduccion;
            this.direccion =resp.direccion;
            if(resp.tipo_operacion.trim().toLowerCase() ==='traduccion'){
              let msg2 = respuesta;
              if(!error){
                msg2 = msg2.replace(/{[0-9]{1,1}:/g, '<span style="color:#358d65">$&</span>');
                msg2 = msg2.replace(/}/g, '<span style="color:#358d65">$&</span>');
                msg2 = msg2.replace(/{/g, '<span style="color:#358d65">$&</span>');

                msg2 = msg2.replace(/[0-9]{3,3}:/g, '<span style="color:RoyalBlue">$&</span>');
                msg2 = msg2.replace(/\:[0-9]{2}[A-Z]{0,1}\:/g, '<span style="color:#f50">$&</span>');
                msg2 = msg2.replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
                msg2 = msg2.replace(/(?:\r\n|\r|\n)/g, '<br>');
              }
              respuestaTotal += msg2;
            }
            else{ 
              let msg2 = respuesta;
              if(!error){
                let msg2Div = this.traducirCustomJson(msg2);
                msg2 = msg2Div.innerHTML;
                let msgProv = msg2Div ;
                
                msgProv.innerHTML = msgProv.innerHTML.replaceAll("</span>","\\n</span>");
                msgProv.innerHTML = msgProv.innerHTML.replaceAll("</p><div>","\\n</p><div>");
                msgProv.innerText = msgProv.innerText.replaceAll("  Encabezado","Encabezado \\n");
                msgProv.innerText = msgProv.innerText.replaceAll("  Documento","\\nDocumento \\n\\n");
                respuesta_para_archivo = msgProv.innerText;
              }
              respuestaTotal += msg2;
            }
            currentJsonObj.content = respuesta_para_archivo;
            }
            else{
              currentJsonObj.content = "";
              currentJsonObj.formato = "";
              currentJsonObj.status = "error";
            }
            completeArray.push(currentJsonObj);
            }
            document.getElementById("outputTextArea").innerHTML = respuestaTotal;
            this.convertido = JSON.stringify(completeArray);
        })
       .catch((err) => {
            this.convertido = "";
            let currentJsonObj = JSON.parse("[]");
            this.convertido = JSON.stringify(currentJsonObj);
              document.getElementById(
                "outputTextArea",
              ).innerHTML = this.mensajeError;

          });
      }
    },
    traducirCustomJson: function(msg2){
      let obj;
      try{
        obj = JSON.parse(msg2);
      } 
      catch{
        obj=msg2;
      }
      let row = document.createElement("div");
      row.id = "mainDataDiv";
      let tab = "";
      
      let completeKey ="";
      let depth = 0;
      this.recurseJson(obj,row,tab,completeKey,depth);
      row.innerHTML= row.innerHTML +"<p><br></p>";
      
      return row;
    },
    recurseJson: function (obj,div,tab,completeKey,depth) { 

        depth++;
        tab = tab+"&nbsp;&nbsp;"
        
        for ( let key in obj ) { // works for objects and arrays 
            let item = obj[key]; 
            let c_completeKey = ''+completeKey;
            if( c_completeKey !==''){
                    c_completeKey =  c_completeKey+"."+key;
            }
            else{
                    c_completeKey = key;
            }
            

            if (typeof item === "object" && (!item.hasOwnProperty("_value") || item._value.trim() ==='' )){
                let current;
                if(depth ===1){
                    current = document.createElement("h3");
                }
                else{
                    current = document.createElement("p");
                }
                item._displayName = ""+item._displayName;
                if(item._displayName.trim() !== 'undefined'){
                  current.innerHTML = tab+item._displayName;
                  
                }
                current.className = depth <4 ?"nivel"+depth: "";
                let currentDiv = document.createElement("div");
                
                currentDiv.appendChild(current);
                div.appendChild(currentDiv);
                
                let keyVal = ''+c_completeKey;
                let c_tab = ''+tab;
                let c_depth = 0 + depth;
                this.recurseJson(item,currentDiv,c_tab,keyVal,c_depth); 
            }        
            else if(item._displayName){
                let keyVal = ''+c_completeKey;
                let current = document.createElement("p");
                let c_tab = ''+tab;
                let currentDiv = document.createElement("div");
                currentDiv.style="display: flex;"
                let currentvalue = document.createElement("span");
                currentvalue.innerText = item._value;
                currentvalue.id = keyVal;
                currentvalue.className = "field_value";
                let textWidth = 200;
                current.className = "texto4_5_etc";
                current.style="width:"+textWidth+"px;";
                let width = div.offsetWidth - textWidth -250;
                currentvalue.style="width:"+width+"px;";
                current.innerHTML = c_tab+item._displayName+"&nbsp;";
                currentDiv.className = "row";
                currentDiv.appendChild(current);
                currentDiv.appendChild(currentvalue);
                div.appendChild(currentDiv);
            }
            
        } 
    },
    download: function () {
       if(this.convertido.length>0){
         let arr= JSON.parse(this.convertido);
        for (let i = 0; i < arr.length; i++){
            let resp = arr[i];
            if(resp.status.trim().toLowerCase() ==='ok' && resp.status.trim() !==''){
                 
              let mensajeArchivo = resp.content.replace(/(?:\\r\\n|\\r|\\n)/g, "%0D%0A");
              let date = this.fecha_traduccion;
              date = date.trim();
              date = date.replace(/-/g,"");
              date = date.replace(/\./g,"");
              date = date.replace(/\:/g,"");
              date = date.replace(/\s/g, "");
              if (navigator.msSaveBlob) {
                  var BOM = "\uFEFF"; 
                  var blobObject = new Blob([resp.content.replace(/\\n/g, "\r\n")], { type: ' type: "text/plain;charset=utf-8"'});
                  window.navigator.msSaveOrOpenBlob(blobObject, "mt_"+date+"_"+this.direccion+".txt");

              }
              else{
                  let hiddenElement = document.createElement("a");
                  hiddenElement.href = "data:text/plain," + mensajeArchivo;
                  hiddenElement.target = "_blank";
                  hiddenElement.download = "mt_"+resp.formato+"_" +date+"_"+this.direccion+".txt";
                  document.body.appendChild(hiddenElement);
                  hiddenElement.click();
                  document.body.removeChild(hiddenElement);
              }
            }
          }
       }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
  },
  mounted() {
  },
    watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.render = false;
      this.$nextTick(() => {
         this.inputData = "";
        this.outputData = "";
        document.getElementById(
                "outputTextArea",
              ).innerHTML = "";
        this.convertido = "";
        this.componentKey++;
        this.$forceUpdate();
      });
    },
  },
};
</script>
