<template>
  <div id="recientes">
    <p><br /></p>
    <div class="section content-title-group">
      <h2 class="title">Convertidos recientemente</h2>
    </div>
    <br />
    <FilterBar :mensajeFiltro="mensajeFiltro" />
    <div
      class="notification is-info textoCentrado"
      style="border-style: !important none"
    >
      <pre style="color: #2ea7d2">{{ actualizado }} {{ resultados }}</pre>
    </div>
    <!-- :fields="fields" :data="registros" -->

    <div style="overflow: auto">
      <table id="tableformat1" style="width: 99% !important">
        <tr>
          <th id="_formato_origen" class="sortable">
            Tipo de operación<i
              class="sort-icon grey sort icon"
              style="opacity: 1; position: relative; float: right"
            ></i>
          </th>
          <th id="_tipo_archivo_origen" class="sortable">
            Formato<i
              class="sort-icon grey sort icon"
              style="opacity: 1; position: relative; float: right"
            ></i>
          </th>
          <th id="_header_from" class="sortable">
            Desde<i
              class="sort-icon grey sort icon"
              style="opacity: 1; position: relative; float: right"
            ></i>
          </th>
          <th id="_header_to" class="sortable">
            Para<i
              class="sort-icon grey sort icon"
              style="opacity: 1; position: relative; float: right"
            ></i>
          </th>
          <th id="_fecha_traduccion" class="sortable">
            Fecha operación<i
              class="sort-icon grey sort icon"
              style="opacity: 1; position: relative; float: right"
            ></i>
          </th>
          <th id="_fprmatodestino" class="sortable">
            Formato destino<i
              style="opacity: 1; position: relative; float: right"
            ></i>
          </th>
          <th>Opciones</th>
        </tr>

        <template v-for="row in registros">
          <tr v-bind:key="row.id">
            <td>{{ row.formato_origen }} - {{row.tipo_operacion==='traduccion'?'traducción':'extracción de datos'}}</td>
            <td>{{ row.tipo_archivo_origen }}</td>
            <td>{{ row.header_from }}</td>
            <td>{{ row.header_to }}</td>
            <td style="text-align: center">{{ row.fecha_traduccion }}</td>
            <td style="text-align: center">{{row.tipo_operacion!=='traduccion'?'información principal':(row.formato_destino+' '+row.tipo_archivo_traduccion+(typeof row.sub_tipo_archivo_traduccion!=='undefined'?' '+row.sub_tipo_archivo_traduccion:''))}}</td>
            <td style="text-align: center">
              &nbsp;&nbsp;
              
              <i v-if="row.tipo_operacion.trim() === 'traduccion'"
                class="bi bi-display"
                @click="
                  mostrarConversion(
                    row.contenido_origen,
                    row.contenido_destino,
                    row.formato_origen,
                  )
                "
                data-toggle="modal"
                data-target="#myModal"
                data-backdrop="static"
                data-focus="true"
                title="Ver conversión"
              ></i>
              <i v-else
                class="bi bi-display"
                @click="
                  mostrarDatosPrincipales(
                    row.contenido_origen,
                    row.maindata,
                    row.formato_origen,
                  )
                "
                data-toggle="modal"
                data-target="#myModal"
                data-backdrop="static"
                data-focus="true"
                style="color: #28a745"
                title="Ver datos principales"
              ></i>
              &nbsp;&nbsp;
              <i v-if="row.tipo_operacion.trim() === 'traduccion'"
                id="b_guardarReporte_MT"
                @click="
                  descargarMT(
                    row.direccion,
                    row.contenido_origen,
                    row.contenido_destino,
                    row.formato_origen,
                    row.fecha_traduccion,
                  )
                "
                class="bi bi-save2"
                style="color: RoyalBlue"
                title="Descargar MT como PDF"
              ></i>
              <i v-else
                id="b_guardarDatosPrincipales"
                @click="
                  descargarMD(
                    row.direccion,
                    row.contenido_origen,
                    row.maindata,
                    row.formato_origen,
                    row.fecha_traduccion,
                  )
                "
                class="bi bi-save2"
                style="color: #28a745"
                title="Descargar datos principales como PDF"
              ></i>
              &nbsp;&nbsp;
              <i v-if="row.tipo_operacion.trim() === 'traduccion'"
                id="b_guardarReporte_MT_TXT"
                @click="
                  descargarMT_TXT(
                    row.direccion,
                    row.contenido_origen,
                    row.contenido_destino,
                    row.formato_origen,
                    row.fecha_traduccion,
                  )
                "
                class="bi bi-save2-fill"
                style="color: RoyalBlue"
                title="Descargar MT como txt"
              ></i>
              <i v-else
                id="b_guardarDatosPrincipales_TXT"
                @click="
                  descargarMD_TXT(
                    row.direccion,
                    row.contenido_origen,
                    row.maindata,
                    row.formato_origen,
                    row.fecha_traduccion,
                  )
                "
                class="bi bi-save2-fill"
                style="color: #28a745"
                title="Descargar datos principales como txt"
              ></i>
              &nbsp;&nbsp;
              <i
                id="b_guardarReporte_MX"
                @click="
                  descargarMX(
                    row.direccion,
                    row.contenido_origen,
                    row.contenido_destino,
                    row.formato_origen,
                    row.fecha_traduccion,
                  )
                "
                class="bi bi-save2"
                title="Descargar MX como PDF"
                style="color: #990000"
              ></i>
              &nbsp;&nbsp;
              <i
                id="b_guardarReporte_MX_TXT"
                @click="
                  descargarMX_TXT(
                    row.direccion,
                    row.contenido_origen,
                    row.contenido_destino,
                    row.formato_origen,
                    row.fecha_traduccion,
                  )
                "
                class="bi bi-save2-fill"
                title="Descargar MX como txt"
                style="color: #990000"
              ></i>
              &nbsp;&nbsp;
            </td>
          </tr>
        </template>
      </table>
    </div>
    <br />
    <br />
    <div class="modal fade" id="myModal">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title" style="text-align: center !important">
              Conversión de mensaje
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="enableIcons"
            >
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div id="modal-row" class="row">
              <p
                disabled
                class="column grooveborder outputArea"
                id="inputTextArea"
              ></p>
              <p
                disabled
                class="column grooveborder outputArea"
                id="outputTextArea"
                style="white-space: nowrap"
              ></p>
            </div>
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="enableIcons"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "../components/SelectionBar0";
import Vue from "vue";
import VueEvents from "vue-events";
import {_tomarRecientes} from "./scripts/translate";
import {jsPDF} from "jspdf";
Vue.use(VueEvents);
Vue.component("filter-bar", FilterBar);
import {mapGetters} from "vuex";

export default {
  name: "Recientes",
  props: {
    space: String,
    spaceid: String,
  },
  components: {
    FilterBar,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      titulo: "Convertidos recientemente",
      registros: [],
      registros_back: null,
      moreParams: {},
      inputData: "",
      outputData: "",
      actualizado: "Cargando...",
      resultados: "",
      componentKey: 0,
      componentKey2: 0,
      mensajeFiltro: "origen, formato, desde, para",
      archivoMT: "",
      archivoMX: "",
      sortOrder: [
        {
          field: "fecha_traduccion",
          direction: "asc",
        },
      ],
    };
  },
  created() {},
  methods: {
    descargarMT: function (inputOutput, cont_origen, cont_destino, tipo, date) {
      let hiddenElement = document.createElement("a");
      if (tipo === "MT") {
        this.archivoMT = "" + JSON.stringify(cont_origen);
      } else {
        this.archivoMT = "" + JSON.stringify(cont_destino);
      }
      this.archivoMT =
        this.archivoMT.charAt(0) === '"'
          ? this.archivoMT.substring(1, this.archivoMT.length)
          : this.archivoMT;
      this.archivoMT =
        this.archivoMT.charAt(this.archivoMT.length - 1) === '"'
          ? this.archivoMT.substring(0, this.archivoMT.length - 1)
          : this.archivoMT;
      let mensajeArchivo = this.archivoMT.replace(
        /(?:\\r\\n|\\r|\\n)/g,
        "%0D%0A",
      );

      let fechaOriginal = date.trim();
      date = date.trim();
      date = date.replace(/-/g, "");
      date = date.replace(/\./g, "");
      date = date.replace(/\:/g, "");
      date = date.replace(/\s/g, "");
      let mensajePDF = this.archivoMT.replace(/(?:\\r\\n|\\r|\\n)/g, "\r\n");
      let mensajePDF_arr = mensajePDF.split("\r\n");

      let mensajeCompleto = "";
      let lineCounter = 0;
      const doc = new jsPDF();
      doc.setFontSize(12);
      doc.setFont("courier");

      for (
        let indiceLinea = 0;
        indiceLinea < mensajePDF_arr.length;
        indiceLinea++
      ) {
        if (lineCounter >= 45) {
          doc.text(mensajeCompleto, 8, 22);
          doc.text(81, 275, "Conversión generada en " + fechaOriginal);
          doc.text(81, 280, "por ISO 20022 Translator - by Plan-IT");
          doc.addPage();
          mensajeCompleto = "";
          lineCounter = 0;
        }
        if (mensajePDF_arr[indiceLinea].length > 62) {
          for (
            let index = 0;
            index < mensajePDF_arr[indiceLinea].length;
            index += 62
          ) {
            mensajeCompleto =
              mensajeCompleto +
              mensajePDF_arr[indiceLinea].substring(index, index + 62) +
              "\r\n";
            lineCounter++;
          }
        } else {
          mensajeCompleto =
            mensajeCompleto + mensajePDF_arr[indiceLinea] + "\r\n";
          lineCounter++;
        }
      }
      doc.text(mensajeCompleto, 8, 22);
      doc.text(81, 275, "Conversión generada en " + fechaOriginal);
      doc.text(81, 280, "por ISO 20022 Translator - by Plan-IT");
      doc.save("mt_" + date + "_" + inputOutput + ".pdf");
    },
    descargarMT_TXT: function (
      inputOutput,
      cont_origen,
      cont_destino,
      tipo,
      date,
    ) {
      let hiddenElement = document.createElement("a");
      if (tipo === "MT") {
        this.archivoMT = "" + JSON.stringify(cont_origen);
      } else {
        this.archivoMT = "" + JSON.stringify(cont_destino);
      }
      this.archivoMT =
        this.archivoMT.charAt(0) === '"'
          ? this.archivoMT.substring(1, this.archivoMT.length)
          : this.archivoMT;
      this.archivoMT =
        this.archivoMT.charAt(this.archivoMT.length - 1) === '"'
          ? this.archivoMT.substring(0, this.archivoMT.length - 1)
          : this.archivoMT;
      let mensajeArchivo = this.archivoMT.replace(
        /(?:\\r\\n|\\r|\\n)/g,
        "%0D%0A",
      );
      hiddenElement.href = "data:text/plain," + mensajeArchivo;
      hiddenElement.target = "_blank";
      let fechaOriginal = date.trim();
      date = date.trim();
      date = date.replace(/-/g, "");
      date = date.replace(/\./g, "");
      date = date.replace(/\:/g, "");
      date = date.replace(/\s/g, "");
      hiddenElement.download = "mt_" + date + "_" + inputOutput + ".txt";
      document.body.appendChild(hiddenElement);
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    },
    descargarMX: function (inputOutput, cont_origen, cont_destino, tipo, date) {
      if (tipo === "MX") {
        this.archivoMX = "" + JSON.stringify(cont_origen);
      } else {
        this.archivoMX = "" + JSON.stringify(cont_destino);
      }
      this.archivoMX =
        this.archivoMX.charAt(0) === '"'
          ? this.archivoMX.substring(1, this.archivoMX.length)
          : this.archivoMX;
      this.archivoMX =
        this.archivoMX.charAt(this.archivoMX.length - 1) === '"'
          ? this.archivoMX.substring(0, this.archivoMX.length - 1)
          : this.archivoMX;
      let hiddenElement = document.createElement("a");
      let mensajeArchivo = this.archivoMX.replace(/\\n/g, "%0D%0A");
      mensajeArchivo = mensajeArchivo.replace(/\\t/g, "%09");
      let fechaOriginal = date.trim();
      date = date.trim();
      date = date.replace(/-/g, "");
      date = date.replace(/\./g, "");
      date = date.replace(/\:/g, "");
      date = date.replace(/\s/g, "");
      let mensajePDF = this.archivoMX.replace(/(?:\\r\\n|\\r|\\n)/g, "\r\n");
      mensajePDF = mensajePDF.replace(/(?:\\t)/g, "    ");
      mensajePDF = mensajePDF.replace(/\\/g, '');
      let mensajePDF_arr = mensajePDF.split("\r\n");

      let mensajeCompleto = "";
      let lineCounter = 0;
      const doc = new jsPDF();
      doc.setFontSize(12);
      doc.setFont("courier");

      for (
        let indiceLinea = 0;
        indiceLinea < mensajePDF_arr.length;
        indiceLinea++
      ) {
        if (lineCounter >= 45) {
          doc.text(mensajeCompleto, 8, 22);
          doc.text(81, 275, "Conversión generada en " + fechaOriginal);
          doc.text(81, 280, "por ISO 20022 Translator - by Plan-IT");
          doc.addPage();
          mensajeCompleto = "";
          lineCounter = 0;
        }
        if (mensajePDF_arr[indiceLinea].length > 65) {
          for (
            let index = 0;
            index < mensajePDF_arr[indiceLinea].length;
            index += 65
          ) {
            mensajeCompleto =
              mensajeCompleto +
              mensajePDF_arr[indiceLinea].substring(index, index + 65) +
              "\r\n";
            lineCounter++;
          }
        } else {
          mensajeCompleto =
            mensajeCompleto + mensajePDF_arr[indiceLinea] + "\r\n";
          lineCounter++;
        }
      }
      doc.text(mensajeCompleto, 8, 22);
      doc.text(81, 275, "Conversión generada en " + fechaOriginal);
      doc.text(81, 280, "por ISO 20022 Translator - by Plan-IT");
      doc.save("mx_" + date + "_" + inputOutput + ".pdf");
    },
    descargarMX_TXT: function (
      inputOutput,
      cont_origen,
      cont_destino,
      tipo,
      date,
    ) {
      if (tipo === "MX") {
        this.archivoMX = "" + JSON.stringify(cont_origen);
      } else {
        this.archivoMX = "" + JSON.stringify(cont_destino);
      }
      this.archivoMX =
        this.archivoMX.charAt(0) === '"'
          ? this.archivoMX.substring(1, this.archivoMX.length)
          : this.archivoMX;
      this.archivoMX =
        this.archivoMX.charAt(this.archivoMX.length - 1) === '"'
          ? this.archivoMX.substring(0, this.archivoMX.length - 1)
          : this.archivoMX;
      let hiddenElement = document.createElement("a");

      let mensajeArchivo = this.archivoMX.replace(/(?:\\r\\n|\\r|\\n)/g, "\r\n")
      mensajeArchivo = mensajeArchivo.replace(/\\n/g, "%0D%0A");
      mensajeArchivo = mensajeArchivo.replace(/\\t/g, "%09");
      mensajeArchivo = mensajeArchivo.replace(/\\/g, '');
      mensajeArchivo = mensajeArchivo.replace("#","%23");
      hiddenElement.href = "data:text/plain," + mensajeArchivo;
      hiddenElement.target = "_blank";
      let fechaOriginal = date.trim();
      date = date.trim();
      date = date.replace(/-/g, "");
      date = date.replace(/\./g, "");
      date = date.replace(/\:/g, "");
      date = date.replace(/\s/g, "");

      hiddenElement.download = "mx_" + date + "_" + inputOutput + ".txt";
      hiddenElement.click();
    },
    descargarMD: function (inputOutput, cont_origen, cont_destino, tipo, date) {
      let msg2Div = this.traducirCustomJson(cont_destino);
      let msgProv = msg2Div ;
      msgProv.innerHTML = msgProv.innerHTML.replaceAll("</span>","\\n</span>");
      msg2Div.innerHTML = msg2Div.innerHTML.replaceAll("&nbsp;&nbsp;","&nbsp;");
      msgProv.innerHTML = msgProv.innerHTML.replaceAll(">\\n</span><div>","></span><div>");
      msg2Div.innerHTML = msg2Div.innerHTML.replaceAll(">&nbsp;Encabezado</h3>",">&nbsp;Encabezado\\n</h3>");
      msg2Div.innerHTML = msg2Div.innerHTML.replaceAll(">&nbsp;Documento</h3>",">&nbsp;\\nDocumento\\n\\n</h3>");
      
      this.archivoMX = msgProv.innerText;
      let hiddenElement = document.createElement("a");
      let mensajeArchivo = this.archivoMX.replace(/\\n/g, "%0D%0A");
      mensajeArchivo = mensajeArchivo.replace(/\\t/g, "%09");
      let fechaOriginal = date.trim();
      date = date.trim();
      date = date.replace(/-/g, "");
      date = date.replace(/\./g, "");
      date = date.replace(/\:/g, "");
      date = date.replace(/\s/g, "");
      let mensajePDF = this.archivoMX.replace(/(?:\\r\\n|\\r|\\n)/g, "\r\n");
      mensajePDF = mensajePDF.replace(/(?:\\t)/g, "    ");
      mensajePDF = mensajePDF.replace(/\\/g, '');
      let mensajePDF_arr = mensajePDF.split("\r\n");

      let mensajeCompleto = "";
      let lineCounter = 0;
      const doc = new jsPDF();
      doc.setFontSize(12);
      doc.setFont("courier");

      for (
        let indiceLinea = 0;
        indiceLinea < mensajePDF_arr.length;
        indiceLinea++
      ) {
        if (lineCounter >= 45) {
          doc.text(mensajeCompleto, 8, 22);
          doc.text(81, 275, "Conversión generada en " + fechaOriginal);
          doc.text(81, 280, "por ISO 20022 Translator - by Plan-IT");
          doc.addPage();
          mensajeCompleto = "";
          lineCounter = 0;
        }
        if (mensajePDF_arr[indiceLinea].length > 65) {
          for (
            let index = 0;
            index < mensajePDF_arr[indiceLinea].length;
            index += 65
          ) {
            mensajeCompleto =
              mensajeCompleto +
              mensajePDF_arr[indiceLinea].substring(index, index + 65) +
              "\r\n";
            lineCounter++;
          }
        } else {
          mensajeCompleto =
            mensajeCompleto + mensajePDF_arr[indiceLinea] + "\r\n";
          lineCounter++;
        }
      }
      doc.text(mensajeCompleto, 8, 22);
      doc.text(81, 275, "Conversión generada en " + fechaOriginal);
      doc.text(81, 280, "por ISO 20022 Translator - by Plan-IT");
      doc.save("mx_" + date + "_" + inputOutput + ".pdf");
    },
    descargarMD_TXT: function (
      inputOutput,
      cont_origen,
      cont_destino,
      tipo,
      date,
    ) {
      
      let msg2Div = this.traducirCustomJson(cont_destino);
      let msgProv = msg2Div ;
      
      msgProv.innerHTML = msgProv.innerHTML.replaceAll("</span>","\\n</span>");
      msg2Div.innerHTML = msg2Div.innerHTML.replaceAll("&nbsp;&nbsp;","&nbsp;");
      msgProv.innerHTML = msgProv.innerHTML.replaceAll(">\\n</span><div>","></span><div>");
      msg2Div.innerHTML = msg2Div.innerHTML.replaceAll(">&nbsp;Encabezado</h3>",">&nbsp;Encabezado\\n</h3>");
      msg2Div.innerHTML = msg2Div.innerHTML.replaceAll(">&nbsp;Documento</h3>",">&nbsp;\\nDocumento\\n\\n</h3>");
      this.archivoMX = msgProv.innerText;

      let mensajeArchivo = this.archivoMX.replace(/\\n/g, "%0D%0A");
      mensajeArchivo = mensajeArchivo.replace(/\\t/g, "%09");
      mensajeArchivo = mensajeArchivo.replace(/\\/g, '');
      let hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/plain," + mensajeArchivo;
      hiddenElement.target = "_blank";
      let fechaOriginal = date.trim();
      date = date.trim();
      date = date.replace(/-/g, "");
      date = date.replace(/\./g, "");
      date = date.replace(/\:/g, "");
      date = date.replace(/\s/g, "");

      hiddenElement.download = "mx_" + date + "_" + inputOutput + ".txt";
      hiddenElement.click();
    },
    clone: function (obj) {
      if (null == obj || "object" != typeof obj) return obj;
      var copy = obj.constructor();
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
      }
      return copy;
    },
    formatXml: function (xml, colorize, indent) {
      function esc(s) {
        try{
          return s.replace(/[-\/&<> ]/g, function (c) {
          return c == " " ? "&nbsp;" : "&#" + c.charCodeAt(0) + ";";
        });
        }
        catch{
          return "";
        }
      }
      var se = '<p class="xel">',
        tb = '<div class="xtb">',
        d = 0,
        i,
        re = "",
        ib,
        sd = '<p class="xdt">',
        tc = '<div class="xtc">',
        ob,
        at,
        sz = "</p>",
        sa = '<p class="xat">',
        tz = "</div>",
        ind = esc(indent || "  ");
      if (!colorize) se = sd = sa = sz = "";
      xml
        .match(/(?<=<).*(?=>)|$/s)[0]
        .split(/>\s*</)
        .forEach(function (nd) {
          ob = nd.match(/^([!?\/]?)(.*?)([?\/]?)$/s); 
          ib = ob[2].match(/^(.*?)>(.*)<\/(.*)$/s) || ["", ob[2], ""];
          at = ib[1].match(/^--.*--$|=|('|").*?\1|[^\t\n\f \/>"'=]+/g) || [""]; 
          if (ob[1] == "/") d--;
          re +=
            tb +
            tc +
            ind.repeat(d) +
            tz +
            tc +
            esc("<" + ob[1]) +
            se +
            esc(at[0]) +
            sz;
          for (i = 1; i < at.length; i += 3)
            re +=
              esc(" ") + sa + esc(at[i]) + sz + "=" + sd + esc(at[i + 2]) + sz;
          re += ib[2]
            ? esc(">") + sd + esc(ib[2]) + sz + esc("</") + se + ib[3] + sz
            : "";
          re += esc(ob[3] + ">") + tz + tz;
          if (ob[1] + ob[3] + ib[2] == "") d++;
        });
      return re;
    },
    enableIcons: function () {
      var els = document.getElementsByClassName("bi bi-display");
      Array.from(els).forEach((el) => {
        el.style.display = "initial";
      });
      var els2 = document.getElementsByClassName("bi bi-save-fill");
      Array.from(els2).forEach((el) => {
        el.style.display = "initial";
      });
    },
    mostrarConversion: function (msg1, msg2, formato) {
      var els = document.getElementsByClassName("bi bi-display");
      var els2 = document.getElementsByClassName("bi bi-save-fill");
      //window.screen.height;
      if (window.screen.width < 800) {
        Array.from(els).forEach((el) => {
          el.style.display = "none";
        });
        Array.from(els2).forEach((el) => {
          el.style.display = "none";
        });
      }

      msg1 = msg1.charAt(0) === '"' ? msg1.substring(1, msg1.length) : msg1;
      msg1 =
        msg1.charAt(msg1.length - 1) === '"'
          ? msg1.substring(0, msg1.length - 1)
          : msg1;
      msg2 = msg2.charAt(0) === '"' ? msg2.substring(1, msg2.length) : msg2;
      msg2 =
        msg2.charAt(msg2.length - 1) === '"'
          ? msg2.substring(0, msg2.length - 1)
          : msg2;
      if (formato === "MT") {
        msg1 = msg1.replace(
          /{[0-9]{1,1}:/g,
          '<span style="color:#358d65">$&</span>',
        );
        msg1 = msg1.replace(/}/g, '<span style="color:#358d65">$&</span>');
        msg1 = msg1.replace(/{/g, '<span style="color:#358d65">$&</span>');

        msg1 = msg1.replace(
          /[0-9]{3,3}:/g,
          '<span style="color:RoyalBlue">$&</span>',
        );
        msg1 = msg1.replace(
          /\:[0-9]{2}[A-Z]{0,1}\:/g,
          '<span style="color:#f50">$&</span>',
        );
        msg1 = msg1.replace(/\\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
        msg1 = msg1.replace(/(?:\r\n|\r|\n)/g, "<br>");

        msg2 = this.formatXml(msg2, true);
      } else {
        msg1 = this.formatXml(msg1, true);
        msg2 = msg2.replace(
          /{[0-9]{1,1}:/g,
          '<span style="color:#358d65">$&</span>',
        );
        msg2 = msg2.replace(/}/g, '<span style="color:#358d65">$&</span>');
        msg2 = msg2.replace(/{/g, '<span style="color:#358d65">$&</span>');

        msg2 = msg2.replace(
          /[0-9]{3,3}:/g,
          '<span style="color:RoyalBlue">$&</span>',
        );
        msg2 = msg2.replace(
          /\:[0-9]{2}[A-Z]{0,1}\:/g,
          '<span style="color:#f50">$&</span>',
        );
        msg2 = msg2.replace(/\\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
        msg2 = msg2.replace(/(?:\r\n|\r|\n)/g, "<br>");
      }
      document.getElementById("inputTextArea").innerHTML = msg1;
      this.componentKey += 1;
      this.$forceUpdate();
      document.getElementById("outputTextArea").innerHTML = msg2;

      this.componentKey2 += 1;
      this.$forceUpdate();
    },
    mostrarDatosPrincipales: function (msg1, msg2, formato) {
      var els = document.getElementsByClassName("bi bi-display");
      var els2 = document.getElementsByClassName("bi bi-save-fill");
      if (window.screen.width < 800) {
        Array.from(els).forEach((el) => {
          el.style.display = "none";
        });
        Array.from(els2).forEach((el) => {
          el.style.display = "none";
        });
      }

      msg1 = msg1.charAt(0) === '"' ? msg1.substring(1, msg1.length) : msg1;
      msg1 =
        msg1.charAt(msg1.length - 1) === '"'
          ? msg1.substring(0, msg1.length - 1)
          : msg1;
      if (formato === "MX") {
       
        msg1 = this.formatXml(msg1, true);
        let msg2Div = this.traducirCustomJson(msg2);
        msg2Div.innerHTML = msg2Div.innerHTML.replaceAll("&nbsp;&nbsp;","&nbsp;");
        msg2 = msg2Div.innerHTML;
      }
      document.getElementById("inputTextArea").innerHTML = msg1;
      this.componentKey += 1;
      this.$forceUpdate();
      document.getElementById("outputTextArea").innerHTML = msg2;

      this.componentKey2 += 1;
      this.$forceUpdate();
    },
    tomarFechaYHoraFormateada: function () {
      var date = new Date();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      var formatedDate =
        "" +
        date.getDate() +
        "/" +
        month +
        "/" +
        date.getFullYear() +
        "  " +
        strTime;
      return formatedDate;
    },
      traducirCustomJson: function(msg2){
      let obj;
      try{
        obj = JSON.parse(msg2);
      } 
      catch{
        obj=msg2;
      }
      let row = document.createElement("div");
      row.id = "mainDataDiv";
      let tab = "";
      
      let completeKey ="";
      let depth = 0;
      this.recurseJson(obj,row,tab,completeKey,depth);
      row.innerHTML= row.innerHTML +"<p><br></p>";
      
      return row;
    },
    recurseJson: function (obj,div,tab,completeKey,depth) { 

        depth++;
        tab = tab+"&nbsp;&nbsp;"
        
        for ( let key in obj ) { // works for objects and arrays 
            let item = obj[key]; 
            let c_completeKey = ''+completeKey;
            if( c_completeKey !==''){
                    c_completeKey =  c_completeKey+"."+key;
            }
            else{
                    c_completeKey = key;
            }
            

            if (typeof item === "object" && (!item.hasOwnProperty("_value") || item._value.trim() ==='' )){
                let current;
                if(depth ===1){
                    current = document.createElement("h3");
                }
                else{
                    current = document.createElement("span");
                }
                item._displayName = ""+item._displayName;
                if(item._displayName.trim() !== 'undefined'){
                  current.innerHTML = tab+item._displayName;
                  current.style ="font-weight: bold;";
                }
                current.className = depth <4 ?"nivel"+depth: "";
                let currentDiv = document.createElement("div");
                
                currentDiv.appendChild(current);
                div.appendChild(currentDiv);
                
                let keyVal = ''+c_completeKey;
                let c_tab = ''+tab;
                let c_depth = 0 + depth;
                this.recurseJson(item,currentDiv,c_tab,keyVal,c_depth); 
            }        
            else if(item._displayName){
                let keyVal = ''+c_completeKey;
                let current = document.createElement("span");
                let c_tab = ''+tab;
                let currentDiv = document.createElement("div");
                currentDiv.style="display: flex;"
                let currentvalue = document.createElement("span");
                currentvalue.innerText = item._value;
                currentvalue.id = keyVal;
                currentvalue.className = "field_value";
                let textWidth = 200;
                current.className = "texto4_5_etc";
                current.style="color: #28a745;font-weight: bold;width:"+textWidth+"px;";
                let width = div.offsetWidth - textWidth -250;
                currentvalue.style="color: black;font-weight: normal;width:"+width+"px;";
                current.innerHTML = c_tab+item._displayName+"&nbsp;";
                currentDiv.className = "row";
                currentDiv.appendChild(current);
                current.appendChild(currentvalue);
                div.appendChild(currentDiv);
            }
            
        } 
    },
    resetearFiltro: function (operacion) {
      if (
        this.registros_back !== null &&
        this.registros_back !== undefined &&
        this.registros_back.length > 0
      ) {
        this.registros = JSON.parse(JSON.stringify(this.registros_back));
      }
      this.resultados = " " + this.registros.length;
      if (this.registros.length === 1) {
        this.resultados += " registro obtenido";
      } else {
        this.resultados += " registros obtenidos";
      }
      var els = document.getElementsByClassName("sortable");
      Array.from(els).forEach((el) => {
        if (el.innerHTML.indexOf("bi bi-arrow-bar-up") > -1) {
          el.innerHTML = el.innerHTML.replace(
            "bi-arrow-bar-up",
            "sort-icon grey sort icon",
          );
        }
        if (el.innerHTML.indexOf("bi bi-arrow-bar-down") > -1) {
          el.innerHTML = el.innerHTML.replace(
            "bi-arrow-bar-down",
            "sort-icon grey sort icon",
          );
        }
      });
    },
    filterValuePart: function (part) {
      if (part.length > 0) {
        part = part.toLowerCase();
        let arr = this.registros.filter(function (el) {
          return (
            el.formato_origen.toLowerCase().indexOf(part) > -1 ||
            el.tipo_archivo_origen.toLowerCase().indexOf(part) > -1 ||
            el.header_from.toLowerCase().indexOf(part) > -1 ||
            el.header_to.toLowerCase().indexOf(part) > -1
          );
        });
        this.registros = arr;
        this.resultados = " " + this.registros.length;
        if (this.registros.length === 1) {
          this.resultados += " registro obtenido";
        } else {
          this.resultados += " registros obtenidos";
        }
      }
    },
    sortResults: function (prop, asc, numeric) {
      let arr = JSON.parse(JSON.stringify(this.registros));
      numeric = false;
      if (numeric) {
        arr.sort(function (a, b) {
          if (asc) {
            return a[prop] - b[prop];
          } else {
            return b[prop] - a[prop];
          }
        });
      } else {
        arr.sort(function (a, b) {
          if (asc) {
            return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
          } else {
            return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
          }
        });
      }
      this.registros = arr;
    },
    getSortParam(sortOrder) {
      return sortOrder
        .map(function (sort) {
          return (sort.direction === "desc" ? "-" : "") + sort.field;
        })
        .join(",");
    },
    cargarOrden() {
      let objectReference = this;
      var els = document.getElementsByClassName("sortable");
      Array.from(els).forEach((el) => {

        el.addEventListener("click", function () {
          var els = document.getElementsByClassName("sortable");
          let sortedType = "";
          Array.from(els).forEach((el) => {
            if (el.id !== this.id) {
              if (el.innerHTML.indexOf("bi bi-arrow-bar-up") > -1) {
                el.innerHTML = el.innerHTML.replace(
                  "bi-arrow-bar-up",
                  "sort-icon grey sort icon",
                );
              }
              if (el.innerHTML.indexOf("bi bi-arrow-bar-down") > -1) {
                el.innerHTML = el.innerHTML.replace(
                  "bi-arrow-bar-down",
                  "sort-icon grey sort icon",
                );
              }
            }
            if (el.id === this.id) {
              if (el.innerHTML.indexOf("sort-icon grey sort icon") > -1) {
                el.innerHTML = el.innerHTML.replace(
                  "sort-icon grey sort icon",
                  "bi bi-arrow-bar-up",
                );
                sortedType = "sorted-asc";
              } else if (el.innerHTML.indexOf("bi-arrow-bar-up") > -1) {
                el.innerHTML = el.innerHTML.replace(
                  "bi-arrow-bar-up",
                  "bi-arrow-bar-down",
                );

                sortedType = "sorted-desc";
              } else if (el.innerHTML.indexOf("bi-arrow-bar-down") > -1) {
                el.innerHTML = el.innerHTML.replace(
                  "bi-arrow-bar-down",
                  "bi-arrow-bar-up",
                );
                sortedType = "sorted-asc";
              }
            }
          });

          let selector = this.id;
          if (
            this !== null &&
            (sortedType === "sorted-asc" || sortedType === "sorted-desc")
          ) {
            let propiedad = selector.substring(1);
            let ascendente = false;
            if (sortedType === "sorted-asc") {
              ascendente = true;
            }

            objectReference.sortResults(propiedad, ascendente, true);
          }
        });
      });
    },
    updateContent: function () {
      let objectReference = this;
      _tomarRecientes(this.space, this.spaceid)
        .then((resp) => {
          objectReference.registros = JSON.parse(
            JSON.stringify(resp.data.data),
          );
          objectReference.registros_back = JSON.parse(
            JSON.stringify(resp.data.data),
          );
          objectReference.actualizado =
            "Actualizado: " + objectReference.tomarFechaYHoraFormateada();
          objectReference.resetearFiltro("reseteo");
          objectReference.cargarOrden();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          //console.log(error);
        });
      this.$events.$on("filter-set", (eventData) =>
        this.filterValuePart(eventData),
      );
      this.$events.$on("filter-reset", (eventData) =>
        this.resetearFiltro(eventData),
      );
    },
  },
  mounted() {
    this.updateContent();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.render = false;
      this.$nextTick(() => {
        this.updateContent();
        this.resetearFiltro("reseteo");
        this.cargarOrden();
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style>
#tableformat1 {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  cursor: pointer;
}

#tableformat1 td,
#tableformat1 th {
  border: 1px solid #ddd;
  padding: 8px;
}

#tableformat1 tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tableformat1 tr:hover {
  background-color: #ddd;
}

#tableformat1 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #2ea7d2;
  color: white;
}
</style>
