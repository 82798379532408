import axios from 'axios';


const iphost =  process.env.VUE_APP_BASE_URL+'/api/v1/middleware';

let config = {
  "Content-Type": "text/plain",
};


function _post(apiPath, datos, callback) {

  axios({
    method: 'post',
    url: iphost + apiPath,
    data: datos,
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      callback(resp);

    })
    .catch(err => {
      callback({status: 400, msg: err});

    });
}
function _get(apiPath, datos, callback) {
  axios({
    method: 'get',
    url: iphost + apiPath,
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      callback(resp);
    })
    .catch(err => {
      callback({status: 400, msg: err});
    });
}
function _postSec(apiPath, datos, access_token, callback) {
  let accesstoken = {
    email: access_token.email,
    firstname: access_token.firstname,
    lastname: access_token.lastname,
    nickname: access_token.nickname,
    fecha_creado: access_token.fecha_creado,
    sessionid: access_token.sessionid,
    token: access_token.token,
  };
  accesstoken = JSON.stringify(accesstoken);

  axios({
    method: 'post',
    url: iphost + apiPath,
    data: datos,
    headers: {
      Authorization: `Bearer:${accesstoken}`,
    },
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      
      callback(resp);
    })
    .catch(err => {
      callback({status: 400, msg: err});
    });
}
function _postSecPlain(apiPath, datos, access_token, callback) {
  let accesstoken = {
    email: access_token.email,
    firstname: access_token.firstname,
    lastname: access_token.lastname,
    nickname: access_token.nickname,
    fecha_creado: access_token.fecha_creado,
    sessionid: access_token.sessionid,
    token: access_token.token,
  };
  accesstoken = JSON.stringify(accesstoken);

  axios({
    method: 'post',
    url: iphost + apiPath,
    data: datos,
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer:${accesstoken}`,
    },
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      
      callback(resp);
    })
    .catch(err => {
      callback({status: 400, msg: err});
    });
}

function _getSec(apiPath, access_token, callback) {
  let accesstoken = {
    email: access_token.email,
    firstname: access_token.firstname,
    lastname: access_token.lastname,
    nickname: access_token.nickname,
    fecha_creado: access_token.fecha_creado,
    sessionid: access_token.sessionid,
    token: access_token.token,
  };

  accesstoken = JSON.stringify(accesstoken);
  axios({
    method: 'get',
    url: iphost + apiPath,
    headers: {
      Authorization: `Bearer:${accesstoken}`,
    },
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      
      callback(resp);
    })
    .catch(err => {
      callback({status: 400, msg: err});
    });
}
/*space relative messages*/
function _postSecPlainSpace(apiPath, datos, access_token, callback) {
  let accesstoken = {
    email: access_token.email,
    firstname: access_token.firstname,
    lastname: access_token.lastname,
    nickname: access_token.nickname,
    fecha_creado: access_token.fecha_creado,
    sessionid: access_token.sessionid,
    token: access_token.token,
    space: access_token.space,
    spaceid: access_token.spaceid,
  };
  accesstoken = JSON.stringify(accesstoken);
  //datos.spaceid = access_token.spaceid;
  axios({
    method: 'post',
    url: iphost + apiPath,
    data: datos,
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer:${accesstoken}`,
    },
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      
      callback(resp);
    })
    .catch(err => {
      callback({status: 400, msg: err});
    });
}
function _getSecSpace(apiPath, access_token, callback) {
  let accesstoken = {
    email: access_token.email,
    firstname: access_token.firstname,
    lastname: access_token.lastname,
    nickname: access_token.nickname,
    fecha_creado: access_token.fecha_creado,
    sessionid: access_token.sessionid,
    token: access_token.token,
    space: access_token.space,
    spaceid: access_token.spaceid,
  };

  accesstoken = JSON.stringify(accesstoken);
  axios({
    method: 'get',
    url: iphost + apiPath,
    headers: {
      Authorization: `Bearer:${accesstoken}`,
    },
  })
    .then(resp => {
      //let regresado = JSON.parse(JSON.stringify(resp.data));
      
      callback(resp);
    })
    .catch(err => {
      callback({status: 400, msg: err});
    });
}
export {_post,_get, _postSec, _getSec,_postSecPlain,_postSecPlainSpace,_getSecSpace};
