import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
Vue.use(VueRouter);
let routes;

    routes = [
        {
          path: '/',
          redirect: '/home',
        },
        {
          path: '/home',
          name: 'home',
          component: Home,
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('../views/login'),
        },
    ];


const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
